<template>
  <v-container>
    <header class="custom-header mb-6">
     <v-row no-gutters>
        <h4>අනුමත සාමාජිකයින්</h4>
        <v-col cols="12" md="3" sm="6">
          <v-btn
            @click="this.clarFilters"
            class="ml-4 mr-2"
            color="accent"
            depressed
            small
          >සෙවීම ඉවත් කරන්න</v-btn>
        </v-col>
     </v-row>
    </header>

    <v-expansion-panels accordion multiple>
      <v-expansion-panel class="custom-shadow">
        <v-expansion-panel-header>
          සොයන්න
          
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                :disabled="horoscopeEmptyList"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සාමාජික අංකය"
                solo
                v-model="queryParams.username"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                :disabled="horoscopeEmptyList"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සම්පූර්ණ නම"
                solo
                v-model="queryParams.fullName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="['ස්ත්‍රී', 'පුරුෂ']"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="ස්ත්‍රී/පුරුෂ"
                solo
                v-model="queryParams.gender"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.marriageStatuses"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="status"
                label="විවාහක තත්වය"
                solo
                v-model="queryParams.marriageStatus"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.countries"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජීවත්වන රට"
                solo
                v-model="queryParams.livingCountry"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.districts"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="දිස්ත්‍රික්කය"
                solo
                v-model="queryParams.district"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    :disabled="horoscopeEmptyList"
                    :items="queryData.heights"
                    @change="fetchApprovedMembers"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(සිට)"
                    solo
                    v-model="queryParams.heightFrom"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :disabled="horoscopeEmptyList"
                    :items="queryData.heights"
                    @change="fetchApprovedMembers"
                    background-color="#fafafa"
                    class="mx-1 my-1"
                    clearable
                    dense
                    flat
                    hide-details
                    item-text="value"
                    label="උස(දක්වා)"
                    solo
                    v-model="queryParams.heightTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.religions"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ආගම"
                solo
                v-model="queryParams.religion"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.races"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ජාතිය"
                solo
                v-model="queryParams.race"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.casts"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="කුළය"
                solo
                v-model="queryParams.cast"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.educationLevels"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="level"
                label="අධ්‍යාපන මට්ටම"
                solo
                v-model="queryParams.educationLevel"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.jobs"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="රැකියා අංශය"
                solo
                v-model="queryParams.job"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.monthlyIncomes"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="value"
                label="මාසික ආදායම"
                solo
                v-model="queryParams.monthlyIncome"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-row no-gutters>
                <v-col>
                  <v-select
                    :disabled="horoscopeEmptyList"
                    :items="queryData.numbersArray"
                    @change="fetchApprovedMembers"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(සිට)"
                    solo
                    v-model="queryParams.ageFrom"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :disabled="horoscopeEmptyList"
                    :items="queryData.numbersArray"
                    @change="fetchApprovedMembers"
                    background-color="#fafafa"
                    class="my-1 mx-1"
                    clearable
                    dense
                    flat
                    hide-details
                    label="වයස(දක්වා)"
                    solo
                    v-model="queryParams.ageTo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="custom-shadow" id="secondary-filter">
        <v-expansion-panel-header>තව දුරටත් සොයන්න</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.lagna"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ලග්නය"
                outlined
                v-model="queryParams.lagnaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.gana"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="ගණය"
                outlined
                v-model="queryParams.ganaya"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.nekath"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                item-text="name"
                label="නැකත"
                outlined
                v-model="queryParams.nekatha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="රවි"
                outlined
                v-model="queryParams.ravi"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="my-1 mx-1"
                clearable
                dense
                flat
                hide-details
                label="චන්ද්‍ර"
                outlined
                v-model="queryParams.chandra"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කුජ"
                outlined
                v-model="queryParams.kuja"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="බුධ"
                outlined
                v-model="queryParams.budha"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ගුරු"
                outlined
                v-model="queryParams.guru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="රාහු"
                outlined
                v-model="queryParams.rahu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="සිකුරු"
                outlined
                v-model="queryParams.sikuru"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="ශනි"
                outlined
                v-model="queryParams.shani"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="queryData.oneToTwelve"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="කේතු"
                outlined
                v-model="queryParams.kethu"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2" sm="4">
              <v-select
                :disabled="horoscopeEmptyList"
                :items="['ඔව්', 'නැත', 'නොදනී']"
                @change="fetchApprovedMembers"
                background-color="#fafafa"
                class="mx-1 my-1"
                clearable
                dense
                flat
                hide-details
                label="පාප කේන්දර"
                outlined
                v-model="queryParams.papaKendara"
              ></v-select>
            </v-col>
            <v-col class="d-flex justify-end" cols="6" md="10" sm="8">
              <v-switch
                @change="fetchHoroscopeEmptyMembers"
                class="mt-3"
                dense
                inset
                v-model="horoscopeEmptyList"
              >
                <template slot="label" v-if="!!$vuetify.breakpoint.mdAndUp">
                  <span class="body-2">කේන්දර හිස් සාමාජිකයන් පමණක් පෙන්වන්න</span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="members"
      :loading="loading"
      :mobile-breakpoint="768"
      :options.sync="options"
      :server-items-length="total"
      @click:row="(member) => $router.push(`/aw-admin/approved-members/${member.id}`)"
      class="custom-shadow custom-radius mt-5"
    >
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.showOnHomePage="{ item }">
        <v-switch
          @change="changeVisibilityOnHomePage(item)"
          @click.native.stop
          class="mt-1"
          color="accent"
          hide-details
          v-model="item.showOnHomePage"
        ></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn @click.stop="openHoroscopeDetailsDialog(item)" color="secondary" depressed icon>
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-btn
          :disabled="item.currentPackageId != 1"
          @click.stop="$router.push(`/aw-admin/approved-members/match/${item.id}`)"
          class="ml-4 mr-2"
          color="accent"
          depressed
          small
        >ගලපන්න</v-btn>
        <v-btn @click.stop="openDialog(item)" color="error" depressed small>අත්හිටුවන්න</v-btn>
      </template>
    </v-data-table>

    <!-- Suspension dialog -->
    <v-dialog max-width="600" persistent v-model="dialog">
      <v-card>
        <v-form @submit.prevent="suspend" lazy-validation ref="form">
          <v-card-text class="pb-0">
            <v-select
              :items="reasons"
              :rules="[v => v.length > 0 || 'අවශ්‍යයි']"
              @input="enableSuspensionReasonTextArea"
              chips
              dense
              label="අත්හිටුවීමට හේතුව"
              multiple
              v-model="suspensionReasonTemp"
            ></v-select>
            <v-textarea
              :rules="[requiredRule, lengthRule]"
              auto-grow
              counter
              label="අත්හිටුවීමට හේතුව"
              rows="1"
              v-if="isEnabledTextArea"
              v-model="suspensionReason"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false" color="error" text>අවලංගු කරන්න</v-btn>
            <v-btn color="success" text type="submit">තහවුරු කරන්න</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Horoscope details dialog -->
    <v-dialog max-width="600" v-model="horoscopeDialog">
      <v-card>
        <v-card-title>කේන්දර විස්තර</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="queryData.lagna"
                dense
                flat
                hide-details
                item-text="name"
                label="ලග්නය"
                v-model="updateForm.lagna"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="queryData.gana"
                dense
                flat
                hide-details
                item-text="name"
                label="ගණය"
                v-model="updateForm.ganaya"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="queryData.nekath"
                dense
                flat
                hide-details
                item-text="name"
                label="නැකත"
                v-model="updateForm.nekatha"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="['ඔව්', 'නැත', 'නොදනී']"
                dense
                flat
                hide-details
                label="පාප කේන්දර"
                v-model="updateForm.papaKendara"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider class="my-6 mx-12"></v-divider>
          <v-row>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="රවි"
                v-model="updateForm.ravi"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="චන්ද්‍ර"
                v-model="updateForm.chandra"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="කුජ"
                v-model="updateForm.kuja"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="බුධ"
                v-model="updateForm.budha"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="ගුරු"
                v-model="updateForm.guru"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="රාහු"
                v-model="updateForm.rahu"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="සිකුරු"
                v-model="updateForm.sikuru"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="ශනි"
                v-model="updateForm.shani"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="queryData.oneToTwelve"
                dense
                flat
                hide-details
                label="කේතු"
                v-model="updateForm.kethu"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="horoscopeDialog = false" color="error" text>අවලංගු කරන්න</v-btn>
          <v-btn @click="updateHoroscope" color="success" text>සුරකින්න</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_ALERT, CHANGE_LOADING_STATE } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";

const initialQueryParams = {
  username: "",
  fullName:"",
  gender: "",
  marriageStatus: "",
  livingCountry: "",
  heightFrom: "",
  heightTo: "",
  race: "",
  religion: "",
  cast: "",
  monthlyIncome: "",
  district: "",
  educationLevel: "",
  job: "",
  lagnaya: "",
  ganaya: "",
  nekatha: "",
  ageFrom: "",
  ageTo: "",
  ravi: "",
  chandra: "",
  kuja: "",
  budha: "",
  guru: "",
  rahu: "",
  sikuru: "",
  shani: "",
  kethu: "",
  papaKendara: "",
}

export default {
  name: "approvedMembers",

  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    lengthRule: v => v.length <= 255 || 'උපරිම අක්ෂර 255 ක් සඳහා පමණක් අවසර ඇත',
    mount: false,
    members: [],
    options: {},
    total: 0,
    loading: false,
    dialog: false,
    horoscopeDialog: false,
    selectedMember: null,
    reasons: [
      'තොරතුරු නිසි ලෙස තහවුරු නොකිරීම',
      'කටයුත්තක් තීන්දුවූ බව දැන්වීම',
      'කටයුත්තක් තීන්දුවී ඇති බවට වෙනත් සාමාජිකයකු විසින් දැනුම් දීම',
      'ඇමතුම් වලට නිසි ප්‍රතිචාර නොදැක්වීම',
      'දුරකථන අංක අවභාවිතය හා අනිසි බලපෑම් ගැන චෝදනා එල්ලවීම',
      'වෙනත්'
    ],
    suspensionReason: '',
    suspensionReasonTemp: [],
    isEnabledTextArea: false,
    headers: [
      { text: "සාමාජික අංකය", value: "id", sortable: false },
      { text: "ලියාපදිංචි වූ දිනය", value: "created_at" },
      { text: "මුල් පිටුව තුල පෙන්වීම", value: "showOnHomePage" },
      { text: "ක්‍රියා", value: "action", align: "right", sortable: false }
    ],
    queryData: {
      marriageStatuses: [],
      countries: [],
      heights: [],
      // races: [],
      religions: [],
      casts: [],
      monthlyIncomes: [],
      // districts: [],
      educationLevels: [],
      jobs: [],
      lagna: [],
      gana: [],
      nekath: [],
      numbersArray: [
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70
      ],
      oneToTwelve: ["-", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
      // smokeUsages: [],
      // foodHabbits: [],
      // countries: []
    },
    queryParams: JSON.parse(JSON.stringify(initialQueryParams)),
    horoscopeEmptyList: false,
    updateForm: {
      customer: "",
      lagna: "",
      ganaya: "",
      nekatha: "",
      papaKendara: "",
      ravi: "",
      chandra: "",
      kuja: "",
      budha: "",
      guru: "",
      rahu: "",
      sikuru: "",
      shani: "",
      kethu: ""
    }
  }),

  beforeMount: function () {
    this.mount = true;
  },

  mounted: async function () {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    this.fetchApprovedMembers();
    await this.fetchQueryData();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  activated: function () {
    if (!this.mount) { // Will call only when page is not mounting
      if (this.horoscopeEmptyList) {
        this.fetchHoroscopeEmptyMembers(true);
      } else {
        this.fetchApprovedMembers();
      }
    }
  },

  deactivated: function () {
    this.mount = false; // Reset mount variable when page is deactivating
  },

  computed: {
    getFilterQuery: function () {
      const usernameParam = this.queryParams.username
        ? { id: this.queryParams.username }
        : {};
      const fullNameParam = this.queryParams.fullName
        ? { fullName: this.queryParams.fullName }
        : {};
      const genderParam = this.queryParams.gender
        ? { gender: this.queryParams.gender }
        : {};

      const marriageStatusParam = this.queryParams.marriageStatus
        ? { marriageStatus: this.queryParams.marriageStatus }
        : {};

      const livingCountryParam = this.queryParams.livingCountry
        ? { livingCountry: this.queryParams.livingCountry }
        : {};

      const heightFromParam = this.queryParams.heightFrom
        ? { heightFrom: this.queryParams.heightFrom }
        : {};

      const heightToParam = this.queryParams.heightTo
        ? { heightTo: this.queryParams.heightTo }
        : {};

      const raceParam = this.queryParams.race
        ? { race: this.queryParams.race }
        : {};

      const religionParam = this.queryParams.religion
        ? { religion: this.queryParams.religion }
        : {};

      const castParam = this.queryParams.cast
        ? { cast: this.queryParams.cast }
        : {};

      const monthlyIncomeParam = this.queryParams.monthlyIncome
        ? { monthlyIncome: this.queryParams.monthlyIncome }
        : {};

      const districtParam = this.queryParams.district
        ? { district: this.queryParams.district }
        : {};

      const educationLevelParam = this.queryParams.educationLevel
        ? { educationLevel: this.queryParams.educationLevel }
        : {};

      const jobParam = this.queryParams.job
        ? { job: this.queryParams.job }
        : {};

      const lagnaParam = this.queryParams.lagnaya
        ? { lagna: this.queryParams.lagnaya }
        : {};

      const ganayaParam = this.queryParams.ganaya
        ? { ganaya: this.queryParams.ganaya }
        : {};

      const nekathaParam = this.queryParams.nekatha
        ? { nekatha: this.queryParams.nekatha }
        : {};

      const ageFromParam = this.queryParams.ageFrom
        ? { ageFrom: this.queryParams.ageFrom }
        : {};

      const ageToParam = this.queryParams.ageTo
        ? { ageTo: this.queryParams.ageTo }
        : {};

      const raviParam = this.queryParams.ravi
        ? { ravi: this.queryParams.ravi }
        : {};

      const chandraParam = this.queryParams.chandra
        ? { chandra: this.queryParams.chandra }
        : {};

      const kujaParam = this.queryParams.kuja
        ? { kuja: this.queryParams.kuja }
        : {};

      const budhaParam = this.queryParams.budha
        ? { budha: this.queryParams.budha }
        : {};

      const guruParam = this.queryParams.guru
        ? { guru: this.queryParams.guru }
        : {};

      const rahuParam = this.queryParams.rahu
        ? { rahu: this.queryParams.rahu }
        : {};

      const sikuruParam = this.queryParams.sikuru
        ? { sikuru: this.queryParams.sikuru }
        : {};

      const shaniParam = this.queryParams.shani
        ? { shani: this.queryParams.shani }
        : {};

      const kethuParam = this.queryParams.kethu
        ? { kethu: this.queryParams.kethu }
        : {};

      const papaKendaraParam = this.queryParams.papaKendara
        ? { papaKendara: this.queryParams.papaKendara }
        : {};

      const params = Object.assign(
        usernameParam,
        fullNameParam,
        genderParam,
        marriageStatusParam,
        livingCountryParam,
        heightFromParam,
        heightToParam,
        religionParam,
        raceParam,
        castParam,
        monthlyIncomeParam,
        districtParam,
        educationLevelParam,
        jobParam,
        lagnaParam,
        ganayaParam,
        nekathaParam,
        ageFromParam,
        ageToParam,
        raviParam,
        chandraParam,
        kujaParam,
        budhaParam,
        guruParam,
        rahuParam,
        sikuruParam,
        shaniParam,
        kethuParam,
        papaKendaraParam
      );

      return params;
    }
  },

  watch: {
    options: {
      handler: function () {
        if (this.horoscopeEmptyList) {
          this.fetchHoroscopeEmptyMembers(true);
        } else {
          this.fetchApprovedMembers();
        }
      },
      deep: true,
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch approved members
    fetchApprovedMembers: async function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

      try {
        const url = "customers";
        const params = {
          params: { approved: 1, nicVerified: 1, mobileNumberVerified: 1, ...pageOptions, ...sortOptions, ...this.getFilterQuery }
        };
        const response = await ApiService.get(url, params);
        this.members = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Fetch data for query
    fetchQueryData: async function () {
      try {
        const url = "metadata";
        const response = await ApiService.get(url);
        this.queryData.marriageStatuses = response.data.marriageStatuses.slice(1);
        this.queryData.countries = response.data.countries;
        this.queryData.heights = response.data.heights;
        this.queryData.religions = response.data.religions.slice(1);
        this.queryData.races = response.data.races.slice(1);
        this.queryData.casts = response.data.casts.slice(1);
        this.queryData.districts = response.data.districts.slice(1);
        this.queryData.educationLevels = response.data.educations.slice(1);
        this.queryData.jobs = response.data.jobs.slice(1);
        this.queryData.monthlyIncomes = response.data.monthlyIncomes.slice(1);
        this.queryData.lagna = response.data.lagna;
        this.queryData.gana = response.data.gana;
        this.queryData.nekath = response.data.nekath;
      } catch (error) {
        console.log(error);
      }
    },

    // Suspend a member
    suspend: async function () {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.loading = true;
        try {
          const url = 'customers/approve';
          const data = {
            approved: false,
            suspensionReason: this.isEnabledTextArea ? this.suspensionReason : this.suspensionReasonTemp.join(),
            customer: this.selectedMember.id
          };
          const response = await ApiService.put(url, data);
          const index = this.members.indexOf(this.selectedMember);
          this.members.splice(index, 1);
          this.showAlert({ message: "ගිණුම අත්හිටුවන ලදි", type: "success" });
          this.$refs.form.resetValidation();
          this.suspensionReason = "";
          this.suspensionReasonTemp = [];
          this.isEnabledTextArea = false;
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    // Open the suspension dialog
    openDialog: function (member) {
      this.dialog = true;
      this.selectedMember = member;
    },

    // Enable suspension reason text area
    enableSuspensionReasonTextArea: function (arr) {
      if (arr.includes("වෙනත්")) {
        this.suspensionReasonTemp = ['වෙනත්'];
        this.isEnabledTextArea = true;
      } else {
        this.isEnabledTextArea = false;
      }
    },

    // Fetch horoscope empty members
    fetchHoroscopeEmptyMembers: async function (value) {
      if (value) {
        this.loading = true;
        this.queryParams = JSON.parse(JSON.stringify(initialQueryParams));

        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const order = sortDesc[0] ? 'desc' : 'asc';
        const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
        const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

        try {
          const url = "customers/get-horoscope-empty-list";
          const params = { params: { ...pageOptions, ...sortOptions } };
          const response = await ApiService.get(url, params);
          this.members = response.data.data;
          this.total = response.data.meta.total;
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      } else {
        this.fetchApprovedMembers();
      }
    },

    // Open the horoscope details dialog
    openHoroscopeDetailsDialog: function (member) {
      this.selectedMember = member;
      this.updateForm = {
        customer: member.id, lagna: member.lagna, ganaya: member.ganaya,
        nekatha: member.nekatha, papaKendara: member.papaKendara,
        ravi: member.ravi,
        chandra: member.chandra,
        kuja: member.kuja,
        budha: member.budha,
        guru: member.guru,
        rahu: member.rahu,
        sikuru: member.sikuru,
        shani: member.shani,
        kethu: member.kethu
      };
      this.horoscopeDialog = true;
    },

    // Update horoscope details of a member
    updateHoroscope: async function () {
      this.horoscopeDialog = false;
      this.loading = true;
      try {
        const url = 'customers/update-horoscope';
        const response = await ApiService.put(url, this.updateForm);
        this.selectedMember.lagna = response.data.lagna;
        this.selectedMember.ganaya = response.data.ganaya;
        this.selectedMember.nekatha = response.data.nekatha;
        this.selectedMember.papaKendara = response.data.papaKendara;
        this.selectedMember.ravi = response.data.ravi;
        this.selectedMember.chandra = response.data.chandra;
        this.selectedMember.kuja = response.data.kuja;
        this.selectedMember.budha = response.data.budha;
        this.selectedMember.guru = response.data.guru;
        this.selectedMember.rahu = response.data.rahu;
        this.selectedMember.sikuru = response.data.sikuru;
        this.selectedMember.shani = response.data.shani;
        this.selectedMember.kethu = response.data.kethu;
        this.showAlert({ message: "කේන්දර විස්තර සංස්කරණය කරන ලදි", type: "success" });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Change member visibility on home page
    changeVisibilityOnHomePage: async function (member) {
      this.loading = true;
      const messageText = member.showOnHomePage ? "ලැයිස්තුවට ඇතුළත් කරන ලදි." : "ලැයිස්තුවෙන් ඉවත් කරන ලදි.";
      try {
        const url = "customers/show-on-homepage";
        const data = { id: member.id, value: member.showOnHomePage };
        const response = await ApiService.put(url, data);
        this.showAlert({ message: `සාමාජිකයා මුල් පිටුව තුල පෙන්වන ${messageText}`, type: "info" });
      } catch (error) {
        member.showOnHomePage = false;
      }
      this.loading = false;
    },

    // Clear all filters
    clarFilters : async function () {
      this.queryParams = JSON.parse(JSON.stringify(initialQueryParams));
      this.fetchApprovedMembers();
    }
  }
};
</script>

<style scoped>
#secondary-filter >>> .v-text-field--outlined fieldset {
  display: none;
}
.v-input--switch {
  display: inline-block;
}
</style>